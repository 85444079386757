import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "ion-text-wrap" }
const _hoisted_2 = { class: "actions" }
const _hoisted_3 = { class: "ion-text-wrap" }
const _hoisted_4 = { class: "ion-text-wrap" }
const _hoisted_5 = { class: "ion-text-wrap" }
const _hoisted_6 = { class: "ion-text-wrap" }
const _hoisted_7 = { class: "ion-text-wrap" }
const _hoisted_8 = {
  key: 0,
  class: "desktop-only"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_MoreJobs = _resolveComponent("MoreJobs")!
  const _component_JobConfiguration = _resolveComponent("JobConfiguration")!
  const _component_MaargJobConfiguration = _resolveComponent("MaargJobConfiguration")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_menu_button, { slot: "start" }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Pre-order")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createElementVNode("section", null, [
              _createVNode(_component_ion_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Pre-sell catalog")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_checkbox, {
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                        checked: _ctx.getStatus(_ctx.jobEnums['REL_PREODR_CAT']),
                        onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateJob($event['detail'].checked, _ctx.jobEnums['REL_PREODR_CAT'])))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Auto refresh pre-sell catalog")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "checked"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.translate("Automatically add and remove products from the pre-order and backorder catalogs based on inventory, purchase orders, and order queues.")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item_divider, { color: "light" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { color: "medium" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("View catalog")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_ion_button, {
                      disabled: !_ctx.preOrderBackorderCategory.preorder,
                      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.goToOmsCategoryPage('/commerce/control/ViewCategory?productCategoryId=' + _ctx.preOrderBackorderCategory.preorder)), ["stop"])),
                      fill: "clear"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate('Pre-Order')) + " ", 1),
                        _createVNode(_component_ion_icon, {
                          slot: "end",
                          icon: _ctx.openOutline
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }, 8, ["disabled"]),
                    _createVNode(_component_ion_button, {
                      disabled: !_ctx.preOrderBackorderCategory.backorder,
                      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.goToOmsCategoryPage('/commerce/control/ViewCategory?productCategoryId=' + _ctx.preOrderBackorderCategory.backorder)), ["stop"])),
                      fill: "clear"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate('Backorder')) + " ", 1),
                        _createVNode(_component_ion_icon, {
                          slot: "end",
                          icon: _ctx.openOutline
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Pre-sell on Shopify")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item_divider, { color: "light" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { color: "medium" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Catalog")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_checkbox, {
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                        checked: _ctx.getStatus(_ctx.jobEnums['PREORDER_CAT_SYC']),
                        onIonChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateJob($event['detail'].checked, _ctx.jobEnums['PREORDER_CAT_SYC'])))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Sync variant details")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "checked"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_checkbox, {
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                        checked: _ctx.getStatus(_ctx.jobEnums['ADD_PRODR_TG_SHPFY']),
                        onIonChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateJob($event['detail'].checked, _ctx.jobEnums['ADD_PRODR_TG_SHPFY'])))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Add pre-order tags")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "checked"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_checkbox, {
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                        checked: _ctx.getStatus(_ctx.jobEnums['REMV_PRODR_TG_SHPFY']),
                        onIonChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateJob($event['detail'].checked, _ctx.jobEnums['REMV_PRODR_TG_SHPFY'])))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Remove pre-order tags")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "checked"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_checkbox, {
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                        checked: _ctx.getStatus(_ctx.jobEnums['ADD_BACKODR_TG_SHPFY']),
                        onIonChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.updateJob($event['detail'].checked, _ctx.jobEnums['ADD_BACKODR_TG_SHPFY'])))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Add backorder tags")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "checked"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_checkbox, {
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                        checked: _ctx.getStatus(_ctx.jobEnums['REMV_BACKODR_TG_SHPFY']),
                        onIonChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateJob($event['detail'].checked, _ctx.jobEnums['REMV_BACKODR_TG_SHPFY'])))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Remove backorder tags")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "checked"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.translate("Sync pre-selling related information to Shopify as tags and meta fields.")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item_divider, { color: "light" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { color: "medium" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Orders")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_checkbox, {
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                        checked: _ctx.getStatus(_ctx.jobEnums['ADD_TAG_PREORD']),
                        onIonChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.updateJob($event['detail'].checked, _ctx.jobEnums['ADD_TAG_PREORD'])))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Add pre-order tags")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "checked"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_checkbox, {
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                        checked: _ctx.getStatus(_ctx.jobEnums['ADD_TAG_BACKORD']),
                        onIonChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.updateJob($event['detail'].checked, _ctx.jobEnums['ADD_TAG_BACKORD'])))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Add backorder tags")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "checked"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.translate("Add pre-order/backorder tags on orders with pre-selling items in them.")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_checkbox, {
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                        checked: _ctx.getStatus(_ctx.jobEnums['UL_PRMS_DTE']),
                        onIonChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.updateJob($event['detail'].checked, _ctx.jobEnums['UL_PRMS_DTE'])))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Add promise date")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "checked"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.translate("Add a note with the promise date given to the customer at the time of placing the order.")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_checkbox, {
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                        checked: _ctx.getStatus(_ctx.jobEnums['UL_PRMS_DTE_UPD']),
                        onIonChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.updateJob($event['detail'].checked, _ctx.jobEnums['UL_PRMS_DTE_UPD'])))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Update promise date")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "checked"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.translate("Add notes to the impacted order items on Shopify for changes promise dates.")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Promise date change")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_checkbox, {
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                        checked: _ctx.getStatus(_ctx.jobEnums['AUTO_SYNC_DT_ODRS']),
                        onIonChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.updateJob($event['detail'].checked, _ctx.jobEnums['AUTO_SYNC_DT_ODRS'])))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Auto sync date to orders")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "checked"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_checkbox, {
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                        checked: _ctx.getStatus(_ctx.jobEnums['SD_PRMSDDTE_CNG_NOTI']),
                        onIonChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.updateJob($event['detail'].checked, _ctx.jobEnums['SD_PRMSDDTE_CNG_NOTI'])))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Email customers")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "checked"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.translate("Notify customers of any changed promise dates for their orders.")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Auto releasing")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_checkbox, {
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                        checked: _ctx.getStatus(_ctx.jobEnums['AUTO_RELSE_DAILY']),
                        onIonChange: _cache[14] || (_cache[14] = ($event: any) => (_ctx.updateJob($event['detail'].checked, _ctx.jobEnums['AUTO_RELSE_DAILY'], 'EVERYDAY')))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Run daily")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "checked"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Release preorders")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_button, {
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                        fill: "outline",
                        onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.runJob(_ctx.jobEnums['AUTO_RELSE_DAILY'])))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Release")), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.translate("Auto releasing pre-orders will find pre-orders that have promise dates that have passed and release them for fulfillment.")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.maargJobs?.length)
                ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_header, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Feed")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.maargJobs, (job, index) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: index,
                          button: "",
                          detail: "",
                          onClick: ($event: any) => (_ctx.viewMaargJobConfiguration(job.jobTypeEnumId))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(job.enumDescription ? job.enumDescription : job.jobName), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_label, { slot: "end" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getMaargJobStatus(job.jobTypeEnumId)), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.getMoreJobs(_ctx.jobEnums, _ctx.enumTypeId).length)
                ? (_openBlock(), _createBlock(_component_MoreJobs, {
                    key: 1,
                    jobs: _ctx.getMoreJobs(_ctx.jobEnums, _ctx.enumTypeId)
                  }, null, 8, ["jobs"]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.isDesktop)
              ? _withDirectives((_openBlock(), _createElementBlock("aside", _hoisted_8, [
                  (_ctx.currentJob)
                    ? (_openBlock(), _createBlock(_component_JobConfiguration, {
                        status: _ctx.currentJobStatus,
                        type: _ctx.freqType,
                        key: _ctx.currentJob
                      }, null, 8, ["status", "type"]))
                    : (Object.keys(_ctx.currentMaargJob).length)
                      ? (_openBlock(), _createBlock(_component_MaargJobConfiguration, { key: _ctx.currentMaargJob }))
                      : _createCommentVNode("", true)
                ], 512)), [
                  [_vShow, _ctx.currentJob || Object.keys(_ctx.currentMaargJob).length]
                ])
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}