import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  slot: "label",
  class: "ion-text-wrap"
}
const _hoisted_2 = {
  key: 0,
  class: "desktop-only"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_MoreJobs = _resolveComponent("MoreJobs")!
  const _component_JobConfiguration = _resolveComponent("JobConfiguration")!
  const _component_MaargJobConfiguration = _resolveComponent("MaargJobConfiguration")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_menu_button, { slot: "start" }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Fulfillment")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createElementVNode("section", null, [
              _createVNode(_component_ion_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Shipping")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, {
                    button: "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.viewJobConfiguration({ id: 'SHIP_PKD_ODRS', status: _ctx.getJobStatus(_ctx.jobEnums['SHIP_PKD_ODRS']) }))),
                    detail: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Ship packed orders")), 1)
                        ]),
                        _: 1
                      }),
                      (!_ctx.isLoading)
                        ? (_openBlock(), _createBlock(_component_ion_label, {
                            key: 0,
                            slot: "end"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTemporalExpression('SHIP_PKD_ODRS')), 1)
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                            key: 1,
                            style: {"width":"30%"},
                            animated: ""
                          }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.translate("Automatically ship orders that are packed and have a tracking number if required.")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("History")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, {
                    button: "",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.viewJobConfiguration({ id: 'ODR_FLMNT_HST', status: _ctx.getJobStatus(_ctx.jobEnums['ODR_FLMNT_HST']) }))),
                    detail: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Order fulfillment")), 1)
                        ]),
                        _: 1
                      }),
                      (!_ctx.isLoading)
                        ? (_openBlock(), _createBlock(_component_ion_label, {
                            key: 0,
                            slot: "end"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTemporalExpression('ODR_FLMNT_HST')), 1)
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                            key: 1,
                            style: {"width":"30%"},
                            animated: ""
                          }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.translate("Create or update order fulfillment history records from FTP.")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Notification")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, {
                    button: "",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.viewJobConfiguration({ id: 'OPN_BOPIS_ORD_NT', status: _ctx.getJobStatus(_ctx.jobEnums['OPN_BOPIS_ORD_NT']) }))),
                    detail: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Open BOPIS order notification")), 1)
                        ]),
                        _: 1
                      }),
                      (!_ctx.isLoading)
                        ? (_openBlock(), _createBlock(_component_ion_label, {
                            key: 0,
                            slot: "end"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTemporalExpression('OPN_BOPIS_ORD_NT')), 1)
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                            key: 1,
                            style: {"width":"30%"},
                            animated: ""
                          }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, {
                    button: "",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.viewJobConfiguration({ id: 'READYPICK_BOPIS_ORD_NT', status: _ctx.getJobStatus(_ctx.jobEnums['READYPICK_BOPIS_ORD_NT']) }))),
                    detail: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Ready to pick BOPIS order notification")), 1)
                        ]),
                        _: 1
                      }),
                      (!_ctx.isLoading)
                        ? (_openBlock(), _createBlock(_component_ion_label, {
                            key: 0,
                            slot: "end"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTemporalExpression('READYPICK_BOPIS_ORD_NT')), 1)
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                            key: 1,
                            style: {"width":"30%"},
                            animated: ""
                          }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, {
                    button: "",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.viewJobConfiguration({ id: 'OPEN_SHIPPING_ORD_NT', status: _ctx.getJobStatus(_ctx.jobEnums['OPEN_SHIPPING_ORD_NT']) }))),
                    detail: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Open shipping order notification")), 1)
                        ]),
                        _: 1
                      }),
                      (!_ctx.isLoading)
                        ? (_openBlock(), _createBlock(_component_ion_label, {
                            key: 0,
                            slot: "end"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTemporalExpression('OPEN_SHIPPING_ORD_NT')), 1)
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                            key: 1,
                            style: {"width":"30%"},
                            animated: ""
                          }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, {
                    button: "",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.viewJobConfiguration({ id: 'BROKER_MAIL_ODR', status: _ctx.getJobStatus(_ctx.jobEnums['BROKER_MAIL_ODR']) }))),
                    detail: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Send Broker Order Mail")), 1)
                        ]),
                        _: 1
                      }),
                      (!_ctx.isLoading)
                        ? (_openBlock(), _createBlock(_component_ion_label, {
                            key: 0,
                            slot: "end"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTemporalExpression('BROKER_MAIL_ODR')), 1)
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                            key: 1,
                            style: {"width":"30%"},
                            animated: ""
                          }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, {
                    button: "",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.viewJobConfiguration({ id: 'PACKED_MAIL_ODR', status: _ctx.getJobStatus(_ctx.jobEnums['PACKED_MAIL_ODR']) }))),
                    detail: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Send Packed Order Mail")), 1)
                        ]),
                        _: 1
                      }),
                      (!_ctx.isLoading)
                        ? (_openBlock(), _createBlock(_component_ion_label, {
                            key: 0,
                            slot: "end"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTemporalExpression('PACKED_MAIL_ODR')), 1)
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                            key: 1,
                            style: {"width":"30%"},
                            animated: ""
                          }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, {
                    button: "",
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.viewJobConfiguration({ id: 'COMPLET_MAIL_ODR', status: _ctx.getJobStatus(_ctx.jobEnums['COMPLET_MAIL_ODR']) }))),
                    detail: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Send Completed Order Mail")), 1)
                        ]),
                        _: 1
                      }),
                      (!_ctx.isLoading)
                        ? (_openBlock(), _createBlock(_component_ion_label, {
                            key: 0,
                            slot: "end"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTemporalExpression('COMPLET_MAIL_ODR')), 1)
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                            key: 1,
                            style: {"width":"30%"},
                            animated: ""
                          }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.translate("Sends notifications for open, ready-for-pickup BOPIS orders and brokered, packed, and completed Sales orders.")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Auto cancelations")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        readonly: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                        placeholder: _ctx.translate('before auto cancelation'),
                        modelValue: _ctx.autoCancelDays,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.autoCancelDays) = $event)),
                        modelModifiers: { number: true },
                        type: "number"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.translate("Days")), 1)
                        ]),
                        _: 1
                      }, 8, ["readonly", "placeholder", "modelValue"]),
                      _createVNode(_component_ion_button, {
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                        fill: "clear",
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.updateAutoCancelDays())),
                        slot: "end"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Save")), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_toggle, {
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                        checked: _ctx.autoCancelCheckDaily,
                        color: "secondary",
                        onIonChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.updateJob($event['detail'].checked, _ctx.jobEnums['AUTO_CNCL_DAL'], 'EVERYDAY')))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Check daily")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "checked"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.translate("Unfulfilled orders that pass their auto cancelation date will be canceled automatically in HotWax Commerce. They will also be canceled in Shopify if upload for canceled orders is enabled.")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.maargJobs?.length)
                ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_header, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Feed")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.maargJobs, (job, index) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: index,
                          button: "",
                          detail: "",
                          onClick: ($event: any) => (_ctx.viewMaargJobConfiguration(job.jobTypeEnumId))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(job.enumDescription ? job.enumDescription : job.jobName), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_label, { slot: "end" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getMaargJobStatus(job.jobTypeEnumId)), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.getMoreJobs({..._ctx.jobEnums, ..._ctx.initialLoadJobEnums}, _ctx.enumTypeId).length)
                ? (_openBlock(), _createBlock(_component_MoreJobs, {
                    key: 1,
                    jobs: _ctx.getMoreJobs({..._ctx.jobEnums, ..._ctx.initialLoadJobEnums}, _ctx.enumTypeId)
                  }, null, 8, ["jobs"]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.isDesktop)
              ? _withDirectives((_openBlock(), _createElementBlock("aside", _hoisted_2, [
                  (_ctx.currentJob)
                    ? (_openBlock(), _createBlock(_component_JobConfiguration, {
                        status: _ctx.currentJobStatus,
                        type: _ctx.freqType,
                        key: _ctx.currentJob
                      }, null, 8, ["status", "type"]))
                    : (Object.keys(_ctx.currentMaargJob).length)
                      ? (_openBlock(), _createBlock(_component_MaargJobConfiguration, { key: _ctx.currentMaargJob }))
                      : _createCommentVNode("", true)
                ], 512)), [
                  [_vShow, _ctx.currentJob || Object.keys(_ctx.currentMaargJob).length]
                ])
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}